
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { isPositiveInteger } from "@/utils/verify";
import noticeChannel from "@/views/marketing/components/notice-channel.vue";
import t from "@common/src/i18n/t";
const marketing = namespace("marketing");
const base = namespace("base");
@Component({
  components: {
    noticeChannel
  }
})
export default class UpkeepAlertSet extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryRemindSetting;
  @marketing.Action createRemindSetting;
  //计算属性
  get breadData() {
    return [];
  }
  selectchannel = [];
  returnForm = {
    noLimit: undefined,
    generationRule: "1",
    advanceDate: undefined,
    radioNumber: undefined,
    enableStatus: 2,
    remindWay: "1",
    pushChannelList: [],
    notifyMethods: [],
    wechatChecked: false,
    messageChecked: false,
    expireMaintainDate: undefined
  };
  multipleValueArr = (rule, value, callback) => {
    if (!value) {
      callback(new Error(t("v210831.please-select-a-limit")));
    } else {
      callback();
    }
  };
  upkeepRule(value, callback) {
    if (isPositiveInteger(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-not-0")));
    }
  }
  numberValueArr = (rule, value, callback) => {
    if (!value) {
      callback(new Error(t("v210831.please-enter-the-time-remind")));
    } else {
      // callback();
      this.upkeepRule(value, callback);
    }
  };
  channel(value, callback) {
    if (this.returnForm.remindWay === "1" && !this.returnForm.notifyMethods.length) {
      callback(new Error(t("v210831.please-select-a-path")));
    } else {
      callback();
    }
  }
  wechatCheckedValueArr = (rule, value, callback) => {
    this.channel(value, callback);
  };
  expireMaintainDateRule = (rule, value, callback) => {
    if (!value) {
      callback();
    } else {
      this.upkeepRule(value, callback);
    }
  };
  returnFormRule = {
    generationRule: [{ validator: this.multipleValueArr, trigger: "blur" }],
    advanceDate: [{ validator: this.numberValueArr, trigger: "blur" }],
    remindWay: [{ required: true, message: t("v210831.please-select-a-type"), trigger: "blur" }],
    wechatChecked: [{ validator: this.wechatCheckedValueArr, trigger: "blur" }],
    messageChecked: [{ validator: this.wechatCheckedValueArr, trigger: "blur" }],
    notifyMethods: [{ required: true, validator: this.wechatCheckedValueArr, trigger: "blur" }],
    enableStatus: [{ required: true, message: t("setting.select-whether-enable"), trigger: "blur" }],
    expireMaintainDate: [{ validator: this.expireMaintainDateRule, trigger: ["blur", "change"] }]
  };
  created() {
    this.init();
  }
  mounted() {}
  init() {
    this.queryRemindSetting().then(res => {
      if (res.data) {
        this.selectchannel = res.data.notifyMethods;
        this.returnForm.notifyMethods = res.data.notifyMethods;
        this.returnForm.advanceDate = res.data.advanceDate;
        this.returnForm.remindWay = res.data.remindWay;
        this.returnForm.enableStatus = res.data.enableStatus;
        this.returnForm.expireMaintainDate = res.data.expireMaintainDate;
      }
    });
  }
  submitForm(returnForm) {
    let refForm = this.$refs[returnForm] as any;
    refForm.validate(valid => {
      if (valid) {
        let data = {
          ...this.returnForm
        };
        this.createRemindSetting(data).then(res => {
          this.$message.success(t("v210831.maintenance-reminder-rule"));
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  changeRevisitType() {
    this.selectchannel = [];
    this.returnForm.notifyMethods = [];
  }
  // ////
}
